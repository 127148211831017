<template>
  <b-field>
    <b-button
      v-if="isDisabled"
      size="is-small"
      disabled
      type="is-danger is-light"
    >
      {{ buttonText }}
    </b-button>
    <b-checkbox-button
      v-else
      v-model="selectableDate"
      :native-value="true"
      :type="buttonType"
      size="is-small"
      :disabled="isDisabled"
    >
      {{ buttonText }}
    </b-checkbox-button>
  </b-field>
</template>
<script>
export default {
  props: {
    title: {
      type: Date,
      required: true,
      default: null,
    },

    active: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  created(){
    //('zzz', this.$store.getters["appcarsService/selectableDate"](this.title), this.selectedDate, this.selectedDate == this.$store.getters["appcarsService/selectableDate"](this.title))
  },
  computed: {
    buttonType() {
      return this.active ? "is-primary" : "is-danger";
    },

    isDisabled() {
      return !this.active;
    },

    buttonText() {
      return this.title.toLocaleTimeString(undefined, {
        hour: "2-digit",
        minute: "2-digit",
      });
    },
    selectedDate(){
      return this.$store.state.appcarsService.order.selectedDate
    },
    selectableDate: {
      get() {
        if(!this.title) return false
        if(!this.selectedDate) return false
        return this.title.toString() == this.selectedDate.toString()
        //return this.$store.getters["appcarsService/selectableDate"](this.title).toString() == this.selectedDate.toString();
      },

      set(value) {
        this.$store.commit("appcarsService/selectDate", {
          title: this.title,
          value: value,
        });
      },
    },
  },
};
</script>
<style lang="scss" scoped>
</style>