<template>
    <article 
        class="media"    
    >
        <div class="media-left">
            <b-image v-if="!company.details.logo_url"
              src="https://bulma.io/images/placeholders/128x128.png"
              alt="Logo"
            ></b-image>
            <img width=320 v-else :src="company.details.logo_url" />
        </div>
        <div class="media-content">
            <div class="content">
                <h1 class="title is-4">{{ company.name }}</h1>
                <div class="block">
                    {{ company.details.description }}
                </div>
                <div class="block">
                    <b-icon icon="map-marker"></b-icon>
                    {{ companyAddressToString(company.address[0]) }}
                </div>
            </div>
        </div>
    </article>
</template>
<script>
export default {
    props: {
        company: {
            type: Object,
            required: true
        },

        isRouterLink: {
            type: Boolean,
            default: false
        }
    },

    methods: {
        companyAddressToString(address) {
            return `${address.street} ${address.local_nb}, ${address.postcode} ${address.city}`;
        },
    }
}
</script>