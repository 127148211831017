<template>
  <div>
    <vue-cal
      active-view="week"
      :disable-views="['years', 'year', 'month', 'day']"
      hide-weekends
      hide-view-selector
      locale="pl"
      :time-from="7 * 60"
      :time-to="17 * 60"
      :time-step="60"
      small
      :events="calendarEvents"
      :selected-date="selectedDate"
    >
      <template v-slot:event="eventProp">
        <div class="vuecal__event">
          <calendar-event-button
            :title="eventProp.event.start"
            :active="eventProp.event.available"
          ></calendar-event-button>
        </div>
      </template>
    </vue-cal>
  </div>
</template>
<script>
import VueCal from "vue-cal";
import "vue-cal/dist/vuecal.css";
import "vue-cal/dist/i18n/pl";

import CalendarEventButton from "@/components/CalendarEventButton";

export default {
  components: {
    "vue-cal": VueCal,
    "calendar-event-button": CalendarEventButton,
  },
  date(){
    return{
    }
  },
  computed: {
    calendarEvents() {
      return this.$store.getters["companyData/getAvailableDates"];
    },
    selectedDate(){
     if(!this.$store.state.appcarsService.order.selectedDate) return new Date()
     else if(this.$store.state.appcarsService.order.selectedDate[10] == 'T') return new Date()       
      else return this.$store.state.appcarsService.order.selectedDate
    }
  },
  created(){
  }
};
</script>
<style>
</style>