<template>
  <div class="container">
    <div v-if="doesCompanyExists">
      <div class="columns">
        <div class="column is-1 pt-0">
          <b-button expanded type="is-primary" icon-left="arrow-left" rounded @click="$router.push({name: 'ExploratorStartSearch'})"
            >Powrót</b-button
          >
        </div>
        <div class="column box is-7">
          <company-details :company="company"></company-details>
          <hr />
          <div>
            <h1 class="title is-6">Nasza oferta</h1>
            <div
              class="columns is-multiline"
              v-for="(category, name) in companyServices"
              :key="`category-${name}`"
            >
              <div class="column is-full">
                <div class="card">
                  <div class="card-header has-background-dark">
                    <div class="card-header-title has-text-white">
                      {{ name }}
                    </div>
                  </div>
                  <div class="card-content">
                    <div
                      class="card my-2"
                      v-for="(service, index) in category"
                      :key="index"
                    >
                      <div class="card-header has-background-primary">
                        <div class="card-header-title has-text-white">
                          {{ service.name }}
                        </div>
                      </div>
                      <div class="card-content">
                        <div class="block">{{ service.description }}</div>
                        <div class="columns is-multiline">
                          <div
                            class="column is-one-third box m-1"
                            v-for="(variant, index) in service.services"
                            :key="`${service.name}-variant-${index}`"
                          >
                            <div class="mb-2">
                              <strong>{{ variant.name }}</strong>
                            </div>
                            <div class="mb-2">
                              {{ variant.description }}
                            </div>
                            <div class="has-text-right">
                              <b-tag type="is-primary">
                                {{ variant.value }} zł
                              </b-tag>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-4 pt-0">
          <div class="card">
            <div class="card-header">
              <div class="card-header-title">Kalendarz</div>
            </div>
            <div class="card-content p-0">
              <service-order-calendar
                v-model="selectedDate"
              ></service-order-calendar>
            </div>
            <div class="card-footer">
              <div class="card-footer-item">
                <b-button
                  type="is-primary"
                  tag="router-link"
                  :to="{
                    name: 'CreateOrder',
                    params: { id: this.$route.params.id },
                  }"
                  expanded
                  >Zamów usługę</b-button
                >
              </div>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </div>
    <div v-else>Błąd pobierania firmy</div>
  </div>
</template>
<script>
import CompanyDetails from "@/components/CompanyDetails";
import ServiceOrderCalendar from "@/components/ServiceOrderCalendar";

export default {
  components: {
    "company-details": CompanyDetails,
    "service-order-calendar": ServiceOrderCalendar,
  },

  data() {
    return {
      selectedDate: new Date(),
    };
  },

  created() {
    this.$store
      .dispatch("appcarsService/getContextCompanyBySlug", this.$route.params.id)
      .then(() => {
        this.$store.dispatch("appcarsService/initDatesMap");
      });

    this.$store.dispatch(
      "companyData/getCompanyServices",
      this.$route.params.id
    );
  },

  computed: {
    company() {
      return this.$store.getters["searchService/getCompanyBySlug"](
        this.$route.params.id
      );
    },

    companyServices() {
      return this.$store.getters["companyData/getCompanyServices"];
    },

    doesCompanyExists() {
      return this.company !== undefined;
    },
  },
};
</script>